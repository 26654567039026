import React from 'react';
import {
	Grid,
	Typography,
	Box,
	Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OPIcon from '../../../../images/icon-op.svg';
import AccountIcon from '../../../../images/icon-account.svg';
import LWIcon from '../../../../images/icon-lw.svg';
import ClubIcon from '../../../../images/icon-club.svg';
import StoreIcon from '../../../../images/icon-store.svg';
import ABIcon from '../../../../images/icon-ab.svg';


const useStyles = makeStyles((theme) => ({
  icon: {
		height: "80px",
	},
	responsiveRows: {
		flexDirection: "row",
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column-reverse",
			alignItems: "stretch"
		}
	}
}));

export default function Bullets() {
	const classes = useStyles();

	const bulletInfo = [
		{icon: OPIcon, msg: "Experience Organized Play to the fullest!"},
		{icon: AccountIcon, msg: "Level up your account, unlock prizes and more!"},
		{icon: StoreIcon, msg: "Support your retailer. Everybody wins!"},
		{icon: ClubIcon, msg: "Equip your Gaming Club with exclusive OP products!"},
		{icon: LWIcon, msg: "Influence the lore and decide event outcomes and future releases!"},
		{icon: ABIcon, msg: "Utilize the free Army Builder!"},
	]

	function bulletize(info, i) {
		return (
			<>
				<Divider />
				{i&1 ? (
					<Grid key={i} container alignItems="center" className={classes.responsiveRows}>
						<Grid item xs={12} sm={10}>
							<Typography variant="h6" align="center">
								{info.msg}
							</Typography>
						</Grid>

						<Grid item xs={12} sm={2}>
							<Box display="flex" justifyContent="flex-end">
								<img src={info.icon} className={classes.icon} />
							</Box>
						</Grid>
					</Grid>
				) : (
					<Grid key={i} container alignItems="center">
						<Grid item xs={12} sm={2}>
							<Box display="flex">
								<img src={info.icon} className={classes.icon} />
							</Box>
						</Grid>		

						<Grid item xs={12} sm={10}>
							<Typography variant="h6" align="center">
								{info.msg}
							</Typography>
						</Grid>
					</Grid>
				)}
			</>
		)
	}

	return (
		<>
			{bulletInfo.map((info, i) => bulletize(info, i))}
			<Divider />
		</>
	)
}