import React, {useState} from 'react';
import {
	IconButton,
	Button,
	Toolbar,
	Box,
	Menu,
	MenuItem,
	Link as SimpleLink,
	makeStyles
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {
	ArrowDropDown,
} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebookF,
	faInstagram,
	faTwitter,
	faYoutube,
	faPinterestP,
	faDiscord
} from '@fortawesome/free-brands-svg-icons';
import {
	faUser,
} from '@fortawesome/free-solid-svg-icons';

const useStyles  = makeStyles({
	toolbar: {
		minHeight: "45px"
	},
	buttonIcon: {
		margin: 0
	},
	socialIcon: {
		fontSize: "20px",
		margin: "0 5px"
	}
});

export default function GuestToolbar() {
	const classes = useStyles();
	const [accountAnchor, setAccountAnchor] = useState(null);
	const [visitAnchor, setVisitAnchor] = useState(null);

	function handleAccountClose() {
		setAccountAnchor(null);
	}

	function handleVisitClose() {
		setVisitAnchor(null);
	}

	return (
		<Toolbar variant="dense" classes={{dense: classes.toolbar}}>
			<Box flexGrow={1}>
				<IconButton color="inherit" className={classes.socialIcon} size="small" href="https://www.facebook.com/ParaBellumWarGames/" target="_blank">
					<FontAwesomeIcon icon={faFacebookF} />
				</IconButton>
				<IconButton color="inherit" className={classes.socialIcon} size="small" href="https://www.instagram.com/parabellum_wargames/" target="_blank">
					<FontAwesomeIcon icon={faInstagram} />
				</IconButton>
				<IconButton color="inherit" className={classes.socialIcon} size="small" href="https://twitter.com/PBwargames" target="_blank">
					<FontAwesomeIcon icon={faTwitter} />
				</IconButton>
				<IconButton color="inherit" className={classes.socialIcon} size="small" href="https://www.youtube.com/channel/UC-2qdsFYAygKPdRYxPVStSA" target="_blank">
					<FontAwesomeIcon icon={faYoutube} />
				</IconButton>
				<IconButton color="inherit" className={classes.socialIcon} size="small" href="https://www.pinterest.ch/parabellumwargames/" target="_blank">
					<FontAwesomeIcon icon={faPinterestP} />
				</IconButton>
				<IconButton color="inherit" className={classes.socialIcon} size="small" href="https://discord.gg/xwsFZqE?fbclid=IwAR1nlfCx0RWkpr6eJ9_SFs4yLai0wm0tBgvNe8kjmOmAYN0Dw5aOE3ep3Co" target="_blank">
					<FontAwesomeIcon icon={faDiscord} />
				</IconButton>
			</Box>

			<Button
				endIcon={<ArrowDropDown />}
				color="inherit"
				classes={{endIcon: classes.buttonIcon}}
				onClick={e => setVisitAnchor(e.currentTarget)}
			>
				Visit
			</Button>

			<Menu
				anchorEl={visitAnchor}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right',}}
				transformOrigin={{ vertical: 'top', horizontal: 'right',}}
				keepMounted
				open={Boolean(visitAnchor)}
				onClose={handleVisitClose}
			>
				<MenuItem onClick={handleVisitClose}>
					<SimpleLink
						href="https://www.para-bellum.com"
						target="_blank"
						underline="none"
						color="inherit"
					>
						Website
					</SimpleLink>
				</MenuItem>

				<MenuItem onClick={handleVisitClose}>
					<SimpleLink
						href="https://armybuilder.para-bellum.com"
						target="_blank"
						underline="none"
						color="inherit"
					>
						Army Builder
					</SimpleLink>
				</MenuItem>
				{/* <SimpleLink underline="none" color="inherit" href="/users/auth/openid_connect"> */}
				{/* </SimpleLink> */}
				{/* {!userSignedIn && <MenuItem component={Link} to="/signup" onClick={handleClose}>Sign up</MenuItem>}
				{userSignedIn && <MenuItem component={Link} to="/profile" onClick={handleClose}>Profile</MenuItem>} */}
			</Menu>

			<IconButton
				color="inherit"
				size="small"
				aria-haspopup="true"
				onClick={e => setAccountAnchor(e.currentTarget)}
			>
				<FontAwesomeIcon icon={faUser} />
			</IconButton>

			<Menu
				anchorEl={accountAnchor}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right',}}
				transformOrigin={{ vertical: 'top', horizontal: 'right',}}
				keepMounted
				open={Boolean(accountAnchor)}
				onClose={handleAccountClose}
			>
				<MenuItem onClick={handleAccountClose}>
					<SimpleLink
						href="/users/auth/openid_connect"
						underline="none"
						color="inherit"
					>
						Sign in
					</SimpleLink>
				</MenuItem>

				<MenuItem
					component={Link}
					to="/signup"
					onClick={handleAccountClose}
				>
					Sign up
				</MenuItem>
				{/* <SimpleLink underline="none" color="inherit" href="/users/auth/openid_connect"> */}
				{/* </SimpleLink> */}
				{/* {!userSignedIn && <MenuItem component={Link} to="/signup" onClick={handleClose}>Sign up</MenuItem>}
				{userSignedIn && <MenuItem component={Link} to="/profile" onClick={handleClose}>Profile</MenuItem>} */}
			</Menu>
		</Toolbar>
	);
}