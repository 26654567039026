import React, {useState, useRef} from 'react'
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Typography,
	TextField,
	Link as SimpleLink,
	FormControlLabel,
	Checkbox,
	Avatar,
	Box,
	Button,
	Container
} from '@material-ui/core'
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from "react-router-dom";
import AccountIcon from '../../../images/icon-account.svg';


export default function SignupForm() {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const params = useLocation();


	const accountData = useRef({
		username: "",
		email: "",
		terms_accepted: false
	});

	const [usernameStatus, setUsernameStatus] = useState({
		error: false,
		message: ""
	});
	const [emailStatus, setEmailStatus] = useState({
		error: false,
		message: "A message will be sent to this address to verify your email and setup your password."
	});
	const [termsStatus, setTermsStatus] = useState("initial")


	function usernameChanged(e) {
		accountData.current.username = e.target.value;
		validateUsername();
	}

	function emailChanged(e) {
		accountData.current.email = e.target.value;
		validateEmail();
	}

	function termsAcceptedChanged(e) {
		accountData.current.terms_accepted = e.target.checked;
		setTermsStatus("initial")
	}


	function validateUsername() {
		if(accountData.current.username.length <= 3) {
			setUsernameStatus({
				error: true,
				message: "Username must be longer than 3 characters."
			});
			return false;
		}	else {
			setUsernameStatus({error: false, message: ""});
			return true;
		}
	}

	function validateEmail() {
		const emailFormat = /[\w+\-.]+@[a-z\d\-]+(\.[a-z]+)*\.[a-z]+/;
		if(emailFormat.test(accountData.current.email)) {
			setEmailStatus({
				error: false,
				message: "A message will be sent to this address to verify your email and setup your password."
			});
			return true;
		}	else {
			setEmailStatus({error: true, message: "Invalid address format."});
			return false;
		}
	}

	function validateTermsAccepted() {
		if (!accountData.current.terms_accepted) {
			setTermsStatus("error")
		};
		return accountData.current.terms_accepted;
	}


	function handleSubmit(e) {
		e.preventDefault();

		let index = params.search.lastIndexOf("?participationId=");
		if (index > -1) {
			accountData.current.participation_id = params.search.slice(index+17);
		}

		if (validateUsername() &&	validateEmail() && validateTermsAccepted()) {
			const csrfToken = document.querySelector('[name=csrf-token').content;
			axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
			axios.post('/api/v1/users', accountData.current)
				.then(function(resp) {
					console.log("success")
					history.push("/")
					enqueueSnackbar("An email will be sent to you shortly.", {variant: 'success'})
				})
				.catch(function(error) {
					error.response.data.messages.forEach(m => enqueueSnackbar(m, {variant: 'error'}))
				})
		}
	}


	return (
		<Container maxWidth="sm" disableGutters>
			<Card>
				<CardHeader
					title="New Account"
					avatar={<Avatar src={AccountIcon} />}
				/>

				<CardContent>
					{/* <form noValidate className={classes.form} onSubmit={handleSubmit}> */}
					<Box flexDirection="column" display="flex">
						<Box my={1}>
							<TextField
								variant="outlined"
								required
								fullWidth
								label="Username"
								size="small"
								helperText={usernameStatus.message}
								error={usernameStatus.error}
								onChange={usernameChanged}
								autoFocus
							/>
						</Box>

						<Box my={1}>
							<TextField
								variant="outlined"
								required
								fullWidth
								label="Email Address"
								type="email"
								autoComplete="email"
								size="small"
								helperText={emailStatus.message}
								error={emailStatus.error}
								onChange={emailChanged}
							/>
						</Box>

						<Box my={1}>
							<FormControlLabel
								control={<Checkbox color="primary" />}
								onChange={termsAcceptedChanged}
								label={<Typography variant="body2" color={termsStatus}>I confirm that I have read, understood and I fully accept the <a href="https://www.para-bellum.com/privacy-policy" target="_blank">Privacy Policy</a> and <a href="https://www.para-bellum.com/terms-of-use" target="_blank">Terms of Use</a> of Para Bellum Wargames.</Typography>}
							/>
						</Box>
						
						<Box my={1} width="100%" maxWidth="300px" alignSelf="center">
							<Button
								fullWidth
								variant="contained"
								color="primary"
								onClick={handleSubmit}
							>
								Sign up
							</Button>
						</Box>
					</Box>
				</CardContent>

				<CardActions>
					<SimpleLink href="/users/auth/openid_connect" underline="none">
						Already have an account? Sign in
					</SimpleLink>
				</CardActions>
			</Card>
		</Container>
	);
}
