import React from 'react';
import {
	Container,
	Box,
	AppBar
} from '@material-ui/core';
import {
	Switch,
	Route,
	Link
} from 'react-router-dom'; 
import { makeStyles } from '@material-ui/core/styles';
import GuestToolbar from './layout/GuestToolbar';
import SignupForm from './users/SignupForm'
import NewStore from './pages/NewStore'
import Home from './pages/Home'
import Logo from '../../images/para-bellum-logo-white.svg'
import Letters from '../../images/enter-the-lw.png'


const useStyles = makeStyles((theme) => ({
	appBar: {
		backgroundColor: "black"
	}
}));

export default function GuestMain() {
	const classes = useStyles();

	return (
		<>
			<AppBar position="sticky" className={classes.appBar}>
				<GuestToolbar />
			</AppBar>

			<Container maxWidth="md" disableGutters>
				<Box px={3} pt={3} display="flex" flexDirection="column" alignItems="center">
					<Box width="50%" maxWidth="165px">
						<Link to="/">
							<img src={Logo} />
						</Link>
					</Box>

					<Box width="95%" maxWidth="350px">
						<img src={Letters} width="100%" />
					</Box>
				</Box>
				
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/signup" component={SignupForm} />
					<Route exact path="/new-store" component={NewStore} />
				</Switch>

				<Box m={4}></Box>
			</Container>
		</>
	)
};
