import React from 'react';
import {
	Grid,
	Button,
} from '@material-ui/core';
import { Link } from 'react-router-dom'

export default function LoginButtons() {
	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} sm={6}>
				<Button
					color="primary"
					size="large"
					variant="contained"
					fullWidth
					href="/users/auth/openid_connect"
				>
					Sign in
				</Button> 
			</Grid>

			<Grid item xs={12} sm={6}>
				<Button
					color="primary"
					size="large"
					variant="contained"
					fullWidth
					component={Link}
					to="/signup"
				>
					Sign up
				</Button>
			</Grid>
		</Grid>
	)
}