import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import GuestMain from '../src/components/GuestMain';
import PBTheme from '../src/themes/pb_theme';


document.addEventListener('DOMContentLoaded', () => {
	let container = document.createElement('div');
	container.id = 'react-container';
	document.body.appendChild(container);

  ReactDOM.render(
		<ThemeProvider theme={PBTheme}>
			<CssBaseline />
			<SnackbarProvider maxSnack={3}>
				<Router>
					<Route path="/" component={GuestMain} />
				</Router>
			</SnackbarProvider>
		</ThemeProvider>,
   container
  )
})
