import React from 'react';
import {
	Paper,
	Box,
	Typography,
} from '@material-ui/core';
import Bullets from './Bullets';
import LoginButtons from './LoginButtons';


export default function Home() {
	return (
		<>
			<Box px={3} mb={10} display="flex" justifyContent="center">
				<Box width="100%" maxWidth="700px">
					<LoginButtons />
				</Box>
			</Box>
			
			<Paper
				component={Box}
				p={3}
				display="flex"
				flexDirection="column"
				alignItems="center"
				style={{backgroundColor: "#FAF9F8E6"}}
			>
				<Typography variant="h5" align="center">
					Welcome to Conquest: The Living World, your portal to a tabletop world of thrilling battles, endless adventure and MINIS! Join now to:
				</Typography>
				
				<Box my={4} width="100%" maxWidth="700px">
					<Bullets />
				</Box>

				<Typography variant="h5" align="center">
					It all starts with creating your own Living World Account. What are you waiting for?
				</Typography>

				<Box mt={4} width="100%" maxWidth="700px">
					<LoginButtons />					
				</Box>
			</Paper>
		</>
	)
}