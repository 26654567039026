import React, {useState, useRef} from 'react'
import {Grid, TextField, Button, Typography, Box} from '@material-ui/core'
import LocationInput from '../common/location_input'
import axios from 'axios';
import { useSnackbar } from 'notistack';

// const useStyles = makeStyles((theme) => ({
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(3),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 1),
//   },
// }));

export default function NewStoreForm(props) {
	const {enqueueSnackbar} = useSnackbar()
	// const [storeData, setStoreData] = useState({
	// 	contact_items_attributes: []
	// 	// name: "",
	// 	// full_address: "",
	// 	// country: "",
	// 	// country_short: "",
	// 	// city: "",
	// 	// administrative1: "",
	// 	// administrative1_short: "",
	// 	// zip: "",
	// 	// url: "",
	// 	// phone: "",
	// 	// email: ""
	// })
	const storeData = useRef({
		name: "",
		url: "",
		phone: "",
		email: ""
	})

	const urlStates = {
		default: {
			error: false,
			message: ""
		},
		missing: {
			error: true,
			message: "Please provide a link to the store's website, Facebook page or any other online resource."
		}
	}
	const [urlState, setUrlState] = useState(urlStates.default)
	const [key, setKey] = useState(1)

	const nameStates = {
		default: {
			error: false,
			message: ""
		},
		missing: {
			error: true,
			message: "Store name cannot be empty."
		}
	}
	const [nameState, setNameState] = useState(nameStates.default)

	const [locationState, setLocationState] = useState("default");


	function handleSubmit(e) {
		e.preventDefault()

		let error = false;
		if (storeData.current.name.length == 0) {
			setNameState(nameStates.missing);
			error = true;
		}
		let acceptedTypes = ["street_address", "intersection", "premise", "subpremise", "plus_code", "park", "point_of_interest"]
		if (!storeData.current.location || !storeData.current.location.types.some(item => acceptedTypes.includes(item))) {
			setLocationState("empty");
			error = true;
		}
		if (storeData.current.url.length == 0) {
			setUrlState(urlStates.missing);
			error = true;
		}

		if (!error) {
			let data = {
				name: storeData.current.name,
				contact_items_attributes: [
					{category: "website", value: storeData.current.url}
				]
			}

			if (storeData.current.location) {
				data.location_attributes = {...storeData.current.location}
			}

			if (storeData.current.phone) {
				data.contact_items_attributes.push({category: "phone", value: storeData.current.phone});
			}
			
			if (storeData.current.email) {
				data.contact_items_attributes.push({category: "email", value: storeData.current.email})
			}

			const csrfToken = document.querySelector('[name=csrf-token').content;
			axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

			axios.post("/api/v1/stores", data)
				.then(response => {
					enqueueSnackbar("Your suggestion has been added to the list and is pending verification.", {variant: 'success'})
					props.onSubmit(response.data.data.id)
					e.target.reset()
					setKey(key + 1)
					storeData.current = {name: "", url: ""}
				})
				.catch(error => {
					enqueueSnackbar("Failed.", {variant: 'error'})
				})
		}
	}

	function handleUrlChange(e) {
		let other = storeData.contact_items_attributes.filter(e => e.category != "website")
		setStoreData({...storeData, contact_items_attributes: [...other, {category: "website", value: e.target.value}]})
	}

	function handleLocationChange(locationData) {
		// setStoreData({...storeData, location_attributes: locationData})
		storeData.current.location = locationData
	}

	return (
		<form noValidate onSubmit={handleSubmit}>
			<Box mb={2}>
			<Typography variant="body2">
				Please fill in as many information as you can. A name, location and URL are required.
			</Typography>
			</Box>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						name="name"
						variant="outlined"
						size="small"
						required
						fullWidth
						id="name"
						label="Name"
						error={nameState.error}
						helperText={nameState.message}
						// onChange={(e) => {setStoreData({...storeData, name: e.target.value})}}
						onChange={e => storeData.current.name = e.target.value}
						onFocus={() => setNameState(nameStates.default)}
					/>
				</Grid>

				<Grid item xs={12}>
					<LocationInput onChange={handleLocationChange} required status={locationState} setStatus={setLocationState} key={key} />
				</Grid>

				<Grid item xs={12}>
					<TextField
						variant="outlined"
						fullWidth
						id="url"
						label="URL"
						placeholder='Website, Facebook, or other web address.'
						required
						name="url"
						type="url"
						size="small"
						error={urlState.error}
						helperText={urlState.message}
						// onChange={handleUrlChange}
						onChange={e => storeData.current.url = e.target.value}
						onFocus={() => setUrlState(urlStates.default)
					}
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<TextField
						variant="outlined"
						fullWidth
						id="email"
						size="small"
						label="Email"
						name="email"
						type="email"
						// onChange={(e) => {setStoreData({...storeData, email: e.target.value})}}
						onChange={(e) => storeData.current.email = e.target.value}
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<TextField
						variant="outlined"
						fullWidth
						id="phone"
						label="Phone"
						size="small"
						name="phone	"
						type="tel"
						// onChange={(e) => {setStoreData({...storeData, phone: e.target.value})}}
						onChange={(e) => storeData.current.phone = e.target.value}
					/>
				</Grid>

				<Grid item xs={12}>
					<Box display="flex" justifyContent="center">
					<Button
						type="submit"
						variant="contained"
						color="primary"
					>
						Submit
					</Button>
					</Box>
				</Grid>
			</Grid>
		</form>
	)
}