import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Bgr  from '../../images/bgr-lw.jpg'

const defaults = createMuiTheme();
let theme = createMuiTheme({
	overrides: {
		MuiCssBaseline: {
			'@global': {
				body: {
					backgroundImage: `url(${Bgr})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center center",
				},
				MuiCardHeaderRoot: {
					backgroundColor: "black"
				}
			}
		},
		MuiCardHeader: {
			root: {
				backgroundColor: "black",
				padding: defaults.spacing(1)
			},
			title: {
				color: "white"
			}
		},
		MuiCardContent: {
			root: {
				paddingTop: defaults.spacing(3)
			}
		},
		MuiAvatar: {
			root: {
				backgroundColor: "white"
			}
		},
	},
	typography: {
		// fontFamily: '"Roboto Condensed", "Roboto", "Helvetica", "Arial", sans-serif',
		h1: {
			fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
			fontSize: "3.75rem",
			fontWeight: "normal"
		},
		h2: {
			fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
			fontSize: "3rem",
			fontWeight: "normal"
		},
		h3: {
			fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
			fontSize: "2.375rem",
			fontWeight: "normal"
		},
		h4: {
			fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
			fontSize: "1.875rem",
			fontWeight: "normal"
		},
		h5: {
			fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
			fontSize: "1.5rem",
			fontWeight: "normal"
		},
		h6: {
			fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
			fontSize: "1.25rem",
			fontWeight: "normal"
		},
		button: {
			textTransform: "none"
		}
	},
	shape: {
		borderRadius: 4
	},
	palette: {
		primary: {main: '#8d021f'},
		background: {
			paper: "#FAF9F8",
			default: "#FAF9F8"
		}
	},
	props: {
		MuiCard: {
			elevation: 5
		},
		MuiCardHeader: {
			titleTypographyProps: {variant: "h5"}
		},
		MuiAvatar: {
			variant: "rounded"
		}
	}
});
const PBTheme = responsiveFontSizes(theme);

export default PBTheme;