import React, {useContext, useEffect, useRef, useState} from 'react';
import {
	Container,
	Card,
	CardHeader,
	CardContent,
	Avatar,
	Grid,
	TextField,
	Typography,
	Button,
	Box,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Slide,
	Link
} from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import StoreIcon from '../../../images/icon-store.svg';
import NewStoreForm from '../Stores/new_store_form'


function newStoreAdded() {
}

export default function NewStore() {

	return (
		<Container maxWidth="sm" disableGutters>
			<Card>
				<CardHeader
					title="New Store"
					avatar={<Avatar src={StoreIcon} />}
				/>

				<CardContent>
					<NewStoreForm onSubmit={newStoreAdded} />
				</CardContent>
			</Card>
		</Container>
	);	
}